$banner-bg: 							$white !default;
$banner-color: 							$color-text !default;

.banner {

	background-color: $banner-bg;
	color: $banner-color;

	@include media-breakpoint-up(md) { padding: 1.5em 0 2em; }
	@include media-breakpoint-up(lg) { padding: 2em 0; }

}