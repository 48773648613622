.block-main.socials {
	
	position: relative;
	z-index: 5000;
	padding: 30px 0 10px !important;
	box-shadow: 0 -2px 5px 0 rgba(0,0,0,0.5);
	background-color: $white;

}


	.awards_list {

		text-align: center;

		li:not(:last-child) { margin-bottom: 1em }

		a {
		    color: $color-text;
		    font-size: 14px;
		    line-height: 1.1em;
		    text-decoration: none;
		    strong { display: block; }
		    &:hover strong { color: theme(secondary, base); }
		}
		img {
			max-width: 125px;
			margin: 0 auto 0.25em;
		}

		@include media-breakpoint-only(sm) {
			overflow: hidden;
			li {
				display: block;
				float: left;
				width: 50%;
			}
		}

		@include media-breakpoint-up(md) {
			li {
				display: block;
				float: left;
				width: 25%;
				img { max-width: 100px; }
			}
		}

	}