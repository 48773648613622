.choose {

	border-top: 0.25rem solid $white;
	border-bottom: 0.25rem solid $white;
	box-shadow: 0 0 5px 0 rgba(0,0,0,0.5);
	color: $white;

	.block_title {
		&:before { content: "Why Choose " }
		&:after { content: "?" }
	}

		.img_satisfaction {
			width: 90%;
			max-width: 240px;
			margin-bottom: 1rem;
		}

		ul {
			line-height: 1em;
			li:not(:last-child) {
				margin-bottom: 0.75rem;
			}
		}

		@include media-breakpoint-up(lg) {
	
			.block_title { margin-bottom: 2.5rem; }

			ul {
				overflow: hidden;
				li {
					float: left;
					width: 45%;
					&:not(:last-child) { margin-bottom: 1rem; }
					&:nth-child(odd) { width: 55%; }
				}
			}

			.img_satisfaction { margin: -80px 0 0; }

		}

		@include media-breakpoint-up(xl) {

			ul > li {
				width: 50%;
				&:nth-child(odd) { width: 50%; }
			}

			.img_satisfaction { margin-right: 100px; }

		}

}