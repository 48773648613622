@mixin btn-ghost($bg: $color-text, $hover: $white) {
    border-color: $bg;
    background: linear-gradient(to left, $white 50%, $bg 50%);
    background-size: 200% 100%;
    background-position: top right;
    color: $bg;
    @include transition(all ease-in-out 300ms);
    &:hover {
        background-position: top left;
        color: $hover;
        @include transition(all ease-in-out 300ms);
    }    
}


[class*="btn_ghost"] {
	@include btn-ghost;	
    border-width: 2px;
}

.btn_ghost-default { @include btn-ghost($color-link); }

.btn_ghost-primary { @include btn-ghost($color-primary); }
.btn_ghost-secondary { @include btn-ghost($color-secondary); }
.btn_ghost-highlight { @include btn-ghost($color-highlight); }
.btn_ghost-accent { @include btn-ghost($color-accent); }
.btn_ghost-success { @include btn-ghost($color-success); }
.btn_ghost-danger { @include btn-ghost($color-danger); }
.btn_ghost-info { @include btn-ghost($color-info); }
.btn_ghost-review { @include btn-ghost($color-review); }
.btn_ghost-warning { @include btn-ghost($color-warning); }