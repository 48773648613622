html {

	font-size: 16px;
	line-height: 1.6em;
	-webkit-text-size-adjust: 100%;

}

body {

	font-size: 1rem;
	font-family: $font-family-default;

}

h1, h2, h3, h4, h5, h6 {
	margin: 2rem 0 1rem;
	font-weight: 400;
	line-height: 1em;
	small { display: block; }
}

h6 {
	font-size: 1rem;
	font-weight: 600;
	letter-spacing: -0.0125em;
}

h5 {
	font-size: 1rem;
	font-weight: 600;
	letter-spacing: -0.0125em;
}

h4 {
	font-size: 1.3rem;
	letter-spacing: -0.015em;
}

h3 {
	font-size: 1.5rem;
	font-weight: 600;
	letter-spacing: -0.0175em;
}

h2 {
	font-size: 1.7rem;
	letter-spacing: -0.02em;
}

h1 {
	font-size: 2rem;
	font-weight: 800;
	letter-spacing: -0.02em;
	small { font-weight: 400 }
	@include media-breakpoint-up(md) { font-size: 2.2rem; }
	@include media-breakpoint-up(lg) { font-size: 2.4rem; }
	@include media-breakpoint-up(xl) { font-size: 2.6rem; }
}

p {
	margin: 0 0 1em;
	&.lead {
		font-size: 125%;
		line-height: (1.5 * 1.02) * 1em;
	}
}

ul, ol {
	margin: 0 0 1em;
	padding: 0 0 0 1.2em;
}

.b0 { font-weight: 400; }
b, strong, .b1 { font-weight: 600; }
.bolder, .strong, .b2 { font-weight: 800; }

address {
	margin-bottom: 1em;
	font-style: normal;
}

blockquote {

	margin: 0 0 1.5em;
	padding: 0.5em 0.5em 0.5em 1em;
	font-size: 1.15rem;
	border-left: 0.5rem solid;

	>*:last-child { margin-bottom: 0; }

	&.bq--text { border-color: $color-text; }
	&.bq--primary { border-color: $color-primary; }
	&.bq--secondary { border-color: $color-secondary; }
	&.bq--highlight { border-color: $color-highlight; }
	&.bq--accent { border-color: $color-accent; }
	&.bq--success { border-color: $color-success; }
	&.bq--danger { border-color: $color-danger; }
	&.bq--warning { border-color: $color-warning; }
	&.bq--info { border-color: $color-info; }

	&.review {
		padding: 0;
		margin: 0 0 1em;
		font-size: 1rem;
		border: none;
	}
}