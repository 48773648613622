.form_review {

	.review_text {
		margin-bottom: 1em;
		.form_control { height: 200px; }
	}

}

	.radio_group {
		margin-bottom: 1em;
		line-height: 1em;
		> label {
			position: relative;
			display: inline-block;
			margin-right: 0.5em;
			min-width: 120px;
			vertical-align: baseline;
			top: -0.35em;
		}
		.error-message { clear: both; margin-top: 0.75em; }
	}

	.rating_recommend {

		p { margin: 0.4em 0 0; padding: 0; }
		
		.error-message { clear: both; margin-top: 0.75em; }

		.radio_yn,
		.radio_yn-label {
			box-sizing: border-box;
			display: inline-block;
			width: 70px;
			height: 36px;
			margin: 0 0 0 -5px;;
			padding: 0.3em 0;
			font-size: 1.3rem;
			line-height: 1em;
			text-align: left;
			vertical-align: top;
			border: 1px solid #ccc;
			outline: none;
			cursor: pointer;

			@include media-breakpoint-up(md) {
				height: 39px
			}

		}

			.radio_yn:checked + .radio_yn-label {
				background-color: $color-primary !important;
				color: $white;
			}

			.radio_yn:hover + .radio_yn-label {
				background-color: #aaa;
			}

			.radio_yn-label {
				margin-left: -70px;
				background-color: $white;
				text-align: center;
			}

	}


.rating {
    overflow: hidden;
    display: inline-block;
}

.rating-input {
    float: right;
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0 0 0 -24px;
    opacity: 0;
}

.rating-star {
    cursor: pointer;
    position: relative;
    float: right;
    display: block;
    width: 28px;
    height: 25px;
    background: url('/_/images/icons/star-rating.png') 0 -24px no-repeat;
}

.rating-star:hover,
.rating-star:hover ~ .rating-star,
.rating-input:checked ~ .rating-star {
    background-position: 0 0;
}


