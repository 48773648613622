.form_newsletter {
	border: none;
	overflow: hidden;
	@include media-breakpoint-up(sm) {
		.form-email {
			float: left;
			width: 75%;
			padding-left: 0;
			.form-control { padding: 11px 6px; }
		}
		.form-submit { float: right; width: 25%; padding-left: 0; }
		.btn {
			margin-top: 6px;
			padding: 8px 16px;
			&:hover { padding: 10px 16px; }		
		}
	}
}
