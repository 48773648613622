.coverage {

	@extend %card;
	padding: 30px;

	.coverage_title {
		margin: 0 0 0.75rem;
		font-size: 1rem;
		font-weight: 800;
		text-transform: uppercase;
	}

	p { 
		margin-bottom: 0.5rem;
		line-height: 1.4em;
		&:last-of-type { margin-bottom: 0; }
	}

		.coverage_county {
			font-size: 0.95rem;
			font-weight: 600;
		}

		.coverage_city {
			font-size: 0.9rem;
		}


}