$nav_count: 					5;
$nav_font_size:					13px;
$nav_font_weight:				800;
$nav_letter_spacing:			-0.0125rem;
$nav_text_transform:			uppercase;

$nav_bg_link: 					theme(primary, base);
$nav_bg_hover: 					theme(primary, dark);

$in_margin_computed:			($nav_count - ($nav_count - 1)) * 1%;
$in_width_computed:				((100 - $nav_count + 1) / $nav_count) * 1%;

$out_margin_computed:			0;
$out_width_computed:			(100 / $nav_count) * 1%;

.banner_menu {

	position: relative;
	z-index: 90000;
	padding-top: 1em;
	font-size: $nav_font_size;
	font-weight: $nav_font_weight;
	letter-spacing: $nav_letter_spacing;
	text-align: center;
	text-transform: $nav_text_transform;

	.nav_menu > .menu_item.active > .menu_link {
		background-color: $white;
		background: theme(secondary, base);
	}

	&.in_header_menu {
		@include media-breakpoint-up(md) {
			.nav_menu > .menu_item {
				display: block;
				float: left;
				width: $in_width_computed;
				&:not(:last-child) { margin-right: $in_margin_computed;	}
				&:nth-child(1),
				&:nth-child(5) {
					.menu_link {
						padding: 19px 15px 18px;
					}
				}
			}
		}
 		@include media-breakpoint-up(lg) {
 			.nav_menu > .menu_item > .menu_link { padding: 15px 5px }
 			.nav_menu > .menu_item:nth-child(1) > .menu_link { padding: 15px; }
 			.nav_menu > .menu_item:nth-child(5) > .menu_link { padding: 22px 15px 21px; }
		}
 		@include media-breakpoint-up(xl) {
 			.nav_menu {
 				.menu_item > .menu_link { padding: 15px 10px }
 				.menu_item:nth-child(1),
 				.menu_item:nth-child(2) {
 					.menu_link { padding: 22px 10px 21px; }
 				}
 			}
		}
	}

	&.out_header_menu {

		@include media-breakpoint-up(md) {

			.nav_menu > .menu_item {
				display: block;
				float: left;
				width: $out_width_computed;
			}

			.nav_menu > .menu_item {
				width: (90 / 5) * 1%;
				> .menu_link { padding: 15px 5px }
			}
			
			.nav_menu > .menu_item:nth-child(1) {
				width: 10%;
				.menu_link {
					text-indent: -9999px;
					background-image: url("/_/images/icons/home_white.svg");
					background-position: center center;
					background-repeat: no-repeat;
					background-size: 1.5rem;
				}
			}

				.nav_menu > .menu_item.active:nth-child(1) {
					.menu_link {
						background-image: url("/_/images/icons/home_active.svg");
					}
				}

		}

		@include media-breakpoint-only(md) {}

		@include media-breakpoint-only(lg) {}

		@include media-breakpoint-up(xl) {}

	}

}



