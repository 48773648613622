$nav_top_bg: 						darken($color-primary, 15%) !default;
//$nav_top_item_count: 				6 !default;
//$nav_top_item_width: 				percentage(1/$nav_top_item_count);
$nav_top_item_width: 				auto;

$nav_top_link_bg:					$nav_top_bg;
$nav_top_link_color:				$white;
$nav_top_hover_bg:					lighten($color-primary, 5%);
$nav_top_hover_color:				$white;
$nav_top_active_bg:					$white;
$nav_top_active_color:				$color-primary;

.nav_top {

	padding: 0;
	background-color: $nav_top_bg;
	@extend %clearfix;

	.nav_menu {
		display: block;
		width: auto;
		float: right;
	}

	.nav_menu > .menu_item { width: $nav_top_item_width;	}

	.menu_item.active .menu_link {
		background-color: $nav_top_active_bg !important;
		color: $nav_top_active_color !important;
	}

		.nav_menu > .menu_item > .menu_link {
			padding: 15px;
			font-size: 14px;
			font-weight: 400;	
			background-color: $nav_top_link_bg;
			color: $nav_top_link_color;
			&:hover, &:focus {
				outline: none;
				background-color: $nav_top_hover_bg;
				color: $nav_top_hover_color;
			}
		}

}