// ===========================================
// BOOTSTRAP GRID MIXINS
// ===========================================

@mixin _assert-ascending($map, $map-name) {
  	$prev-key: null;
  	$prev-num: null;
  	@each $key, $num in $map {
    	@if $prev-num == null {
      	// Do nothing
    	} @else if not comparable($prev-num, $num) {
      		@warn "Potentially invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} whose unit makes it incomparable to #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    	} @else if $prev-num >= $num {
      		@warn "Invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} which isn't greater than #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    	}
    	$prev-key: $key;
    	$prev-num: $num;
  	}
}

@mixin _assert-starts-at-zero($map) {
  	$values: map-values($map);
  	$first-value: nth($values, 1);
  	@if $first-value != 0 {
    	@warn "First breakpoint in `$grid-breakpoints` must start at 0, but starts at #{$first-value}.";
  	}
}

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  	$min: breakpoint-min($name, $breakpoints);
  	@if $min {
    	@media (min-width: $min) {
      		@content;
    	}
  	} @else {
	    @content;
  	}
}

@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
	$max: breakpoint-max($name, $breakpoints);
  	@if $max {
		@media (max-width: $max) {
      		@content;
    	}
  	} @else {
    	@content;
  	}
}

@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
  	$min: breakpoint-min($lower, $breakpoints);
  	$max: breakpoint-max($upper, $breakpoints);
  	@media (min-width: $min) and (max-width: $max) {
    	@content;
  	}
}

@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
	$min: breakpoint-min($name, $breakpoints);
  	$max: breakpoint-max($name, $breakpoints);
  	@if $min != null and $max != null {
    	@media (min-width: $min) and (max-width: $max) {
      		@content;
    	}
  	} @else if $max == null {
    	@include media-breakpoint-up($name)
  	} @else if $min == null {
    	@include media-breakpoint-down($name)
	}
}

@mixin make-container() {
	margin-right: auto;
	margin-left: auto;
	padding-right: ($grid-gutter-width / 2);
	padding-left:  ($grid-gutter-width / 2);
	width: 100%;
}

@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
	@each $breakpoint, $container-max-width in $max-widths {
    	@include media-breakpoint-up($breakpoint, $breakpoints) {
      		max-width: $container-max-width;
    	}
  	}
}

@mixin make-row() {
	display: flex;
	flex-wrap: wrap;
	margin-right: ($grid-gutter-width / -2);
	margin-left:  ($grid-gutter-width / -2);
}

@mixin make-col-ready() {
	position: relative;
	width: 100%;
	min-height: 1px;
	padding-right: ($grid-gutter-width / 2);
	padding-left:  ($grid-gutter-width / 2);
}

@mixin make-col($size, $columns: $grid-columns) {
	flex: 0 0 percentage($size / $columns);
	max-width: percentage($size / $columns);
}

// ===========================================
// BOOTSTRAP COMPONENT MIXINS - 3.3.7
// ===========================================

@mixin img-responsive($display: block) {
    display: $display;
    max-width: 100%; // Part 1: Set a maximum relative to the parent
    height: auto; // Part 2: Scale the height according to the width, otherwise you get stretching
}

@mixin transition-transform($transition...) {
    -webkit-transition: -webkit-transform $transition;
     -moz-transition: -moz-transform $transition;
       -o-transition: -o-transform $transition;
          transition: transform $transition;
}

@mixin backface-visibility($visibility) {
    -webkit-backface-visibility: $visibility;
     -moz-backface-visibility: $visibility;
          backface-visibility: $visibility;
}

@mixin perspective($perspective) {
    -webkit-perspective: $perspective;
     -moz-perspective: $perspective;
          perspective: $perspective;
}

@mixin translate3d($x, $y, $z) {
    -webkit-transform: translate3d($x, $y, $z);
            transform: translate3d($x, $y, $z);
}

@mixin gradient-horizontal($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
    background-image: -webkit-linear-gradient(left, $start-color $start-percent, $end-color $end-percent); // Safari 5.1-6, Chrome 10+
    background-image: -o-linear-gradient(left, $start-color $start-percent, $end-color $end-percent); // Opera 12
    background-image: linear-gradient(to right, $start-color $start-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=1); // IE9 and down
}

// ===========================================
// CUSTOM MIXINS
// ===========================================
@mixin transition($arg) {
    -webkit-transition: $arg;
       -moz-transition: $arg;
        -ms-transition: $arg;
         -o-transition: $arg;
            transition: $arg;
}

@mixin transform($arg) {
    -webkit-transform: $arg;
       -moz-transform: $arg;
         -o-transform: $arg;
            transform: $arg;
}

@mixin gdfancy-bg($arg) {
    background: $arg;
	background: linear-gradient(45deg, rgba(0,0,0,0.25) 0%,rgba(0,0,0,0) 25%,rgba(0,0,0,0) 75%,rgba(0,0,0,0.25) 100%),
				linear-gradient(135deg, rgba(0,0,0,0.25) 0%,rgba(0,0,0,0.25) 10%,rgba(0,0,0,0) 45%,rgba(0,0,0,0) 55%,rgba(0,0,0,0.25) 90%,rgba(0,0,0,0.25) 100%),
				linear-gradient(45deg, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 20%,rgba(200,200,200,0.1) 35%,rgba(255,255,255,0) 35%,rgba(255,255,255,0) 100%),
				linear-gradient(45deg, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 60%,rgba(200,200,200,0.1) 75%,rgba(255,255,255,0) 75%,rgba(255,255,255,0) 100%),
				linear-gradient(135deg, rgba(255,255,255,0) 25%,rgba(255,255,255,0) 25%,rgba(200,200,200,0.1) 50%,rgba(255,255,255,0) 75%,rgba(255,255,255,0) 100%),
				$arg;
}

@mixin font-size($size, $base: 16) {
    font-size: $size;
    font-size: calculateRem($size, $base);
}

@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity = $opacity-ie); //IE8
}

@mixin prefix($property, $value, $vendors: webkit moz ms o, $default: true) {
    @if $vendors {
        @each $vendor in $vendors {
            #{"-" + $vendor + "-" + $property}: #{$value};
        }
    }
    @if $default {
        #{$property}: #{$value};
    }
}


