.banner_branding {

	.branding_logo {
		
		display: block;
		margin: 1em auto 0;
		width: 240px;
		height: 85px;

		line-height: 1em;
		text-align: center;
		text-decoration: none;
		text-indent: -9999px;
		
		background-image: url("/_/images/layout/ba-junk-removal.png");
		background-size: cover;
		background-repeat: no-repeat;
		color: $color-text;

	}

	@include media-breakpoint-up(sm) {
		.branding_logo {
			width: 360px;
			height: 128px;
		}
	}

	@include media-breakpoint-only(lg) {
		.branding_logo {
			width: 290px;
			height: 103px;
		}
	}

	@include media-breakpoint-up(lg) {
		.branding_logo { margin: 0 }
	}

}

