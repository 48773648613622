.nav_bar {

	padding: 0;
	
	.nav_menu > .menu_item { text-align: center }
	.nav_menu > .menu_item:not(:last-child) { border-right: 1px solid rgba(0,0,0,1); }
	.nav_menu > .menu_item:not(:first-child) { border-left: 1px solid rgba(255,255,255,0.3); }

	.nav_menu > .menu_item > .menu_link { padding: 18px 15px }

	&.nav--primary {
		background-color: theme(primary, base);
		.menu_link {
			color: $white;
			&:hover { background-color: theme(primary, light) }
		}
		.open > .menu_link { background-color: theme(primary, base); }
		.dropdown_menu { background-color: theme(primary, base); }
	}


}