.about_city {

	margin-bottom: 1em;
	padding: 15px;
	font-size: 85%;
	line-height: 1.4em;
	border: 1px solid #ccc;
	border-left: 10px solid theme(text, base);
	background-color: rgba($white,0.5);

	.city_title {
		margin-top: 15px;
		font-weight: 800;
	}

}