.btn_3d {
	
	padding: 0.75rem 1rem;
	border-width: 0.05rem;
	border-bottom-width: 0.25rem;
    @include transition(all ease-in-out 150ms);

	&:hover {
		padding: 0.85rem 1rem;
		border-bottom-width: 0.05rem;
    	@include transition(all ease-in-out 150ms);
	}

}