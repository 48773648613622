%grad-img-review {
	background: 	linear-gradient(45deg, rgba(0,0,0,0.1) 0%,rgba(0,0,0,0) 25%,rgba(0,0,0,0) 75%,rgba(0,0,0,0.1) 100%),
					linear-gradient(135deg, rgba(0,0,0,0.1) 0%,rgba(0,0,0,0.1) 10%,rgba(0,0,0,0) 45%,rgba(0,0,0,0) 55%,rgba(0,0,0,0.1) 90%,rgba(0,0,0,0.1) 100%),
					linear-gradient(45deg, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 20%,rgba(255,255,255,0.22) 35%,rgba(255,255,255,0) 35%,rgba(255,255,255,0) 100%),
					linear-gradient(45deg, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 60%,rgba(255,255,255,0.22) 75%,rgba(255,255,255,0) 75%,rgba(255,255,255,0) 100%),
					linear-gradient(135deg, rgba(255,255,255,0) 0,rgba(255,255,255,0) 15%,rgba(255,255,255,0.3) 50%,rgba(255,255,255,0) 90%,rgba(255,255,255,0) 100%),
					$color-review;
}


.home-reviews {

	@extend %grad-img-review;
	text-align: center;
	//border-top: 5px solid #fff;
	border-bottom: 5px solid #fff;

	.block__title {
		margin-top: 0;
		margin-bottom: 1em;
		font-weight: 800;
		text-shadow: 0 1px 1px rgba(0,0,0,0.6);
		color: #fff;
	}

	a { font-weight: 800; }

	@include media-breakpoint-up(sm) {
		a { font-size: ms(3); }
	}


	@include media-breakpoint-only(md) {

		.review {
			&:nth-child(1),
			&:nth-child(2) {
				float: left;
				width: 48.5%;
			}
			&:nth-child(2) { float: right; }
			&:nth-child(3) { clear: both; }
		}

	}

	@include media-breakpoint-up(lg) {
		padding: 2em 0;

		.review {
			float: left;
			width: (96% / 3);
			margin-right: 2%;
			&:nth-child(3) { margin-right: 0; }
		}

	}

}

	.review {

		position: relative;
		margin-bottom: 2em;
		padding: 2em 1em;
		border-radius: 4px;
		background-color: #fff;
		box-shadow: 0 1.5em 1em -1em rgba(0,0,0,0.3);

		strong {
			display: block;
			font-size: 115%;
			line-height: 1.1em;
			font-family: $font-family-sans-serif;
		}

		.review__stars {
			max-width: 150px;
			margin: 0.5em auto;
		}

		&:after {
			content: "";
			position: absolute;
			bottom: -1em;
			left: 45.25%;
			border-width: 1em 1.5em 0;
			border-style: solid;
			border-color: #fff transparent;
			display: block;
			width: 0;
		}

	}
