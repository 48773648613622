.jumbo {

	position: relative;
	padding: 0;
	@include gdfancy-bg($color-highlight);
	//background-image: url("/_/images/layout/jumbo-sm.jpg");
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;

	@include media-breakpoint-up(md) {
		background-image: url("/_/images/layout/jumbo-md.jpg");
	}

	@include media-breakpoint-up(lg) {
		background-color: $white;
		background-image: url("/_/images/layout/jumbo-lg.jpg");
	}

	@media (min-width: 1600px) {
		background-size: contain;
	}

}

.jumbo_marketing {

	padding: 2em 15px;
	text-align: center;
	color: $white;

	.btn {
		@include btn-reg($color-primary);
		font-size: 125%;
		font-weight: 800;
		text-transform: uppercase;
	}

	@include media-breakpoint-up(md) {
		padding: 4em 15px;
		.btn {
			@include btn-reg($color-highlight);
			padding: 0.75em 1em;
		}
	}

	@include media-breakpoint-up(lg) { padding: 6em 15px; }
	
	@include media-breakpoint-up(xl) { padding: 8em 15px; }

}

	.marketing_title {
		
		margin-bottom: 1em;
		font-size: 1.5rem;
		line-height: 1.225em;
		text-shadow: 0 1px 2px rgba(0,0,0,0.8);

		@include media-breakpoint-up(sm) {}

		@include media-breakpoint-up(md) {
			font-size: 1.75rem;
			line-height: 1.425em;
			span {
				padding: 0.325em 0;
				background-color: rgba(0,0,0,0.75);
				box-shadow: 0.6em 0 0 0 rgba(0,0,0,0.75),
							-0.6em 0 0 0 rgba(0,0,0,0.75);
			}			
		}

		@include media-breakpoint-up(lg) {
			font-size: 2rem;
		}

		@include media-breakpoint-up(xl) {
			font-size: 2.3rem;
		}

	}

		.marketing_details {

			font-size: 1.075rem;
			font-weight: 600;
			line-height: 1.3em;
			text-shadow: 0 1px 2px rgba(0,0,0,0.8);

			@include media-breakpoint-up(md) {
				font-size: 1.175rem;
				line-height: 1.6em;
				span {
					padding: 0.25em 0;
					background-color: rgba(0,0,0,0.75);
					box-shadow: 0.5em 0 0 0 rgba(0,0,0,0.75),
								-0.5em 0 0 0 rgba(0,0,0,0.75);
				}				
			}

			@include media-breakpoint-up(lg) {
				font-size: 1.25rem;
			}

			@include media-breakpoint-up(xl) {
				font-size: 1.4rem;
			}

		}

.jumbo_conversion {}