$contact_title_color: 				theme(primary, base);
$contact_phone_color: 				theme(secondary, base);

.banner_contact {

	text-align: center;

	p { margin-bottom: 0 }

	@include media-breakpoint-up(md) {
		text-align: right;
	}

}

	.contact_title {
		display: block;
		font-size: 1.25rem;
		font-weight: 600;
		line-height: 1em;
		letter-spacing: -0.04em;
		color: $contact_title_color;
	}

	.contact_phone {
		font-size: 2rem;
		font-weight: 800;
		line-height: 1em;
		letter-spacing: -0.04em;
		color: $contact_phone_color;
		&:hover { color: $contact_phone_color; outline: none; }
	}