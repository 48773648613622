.info_copyright {

	padding-top: 1em;
	font-size: 0.85rem;
	line-height: 1.3em;
	text-align: center; 

	a {
		font-size: 1rem;
		font-weight: 600;
		line-height: 1em;
		text-decoration: none;
		color: $white;
	}

	@include media-breakpoint-up(md) {
		padding-top: 0;
		text-align: right;
	}

}