.blog_roll {}

	.roll_item {
		@extend %card;
		margin: 0 0 2em;
		padding: 1.25em;
	}

		.roll_header {
			a {
				@include transition(background ease-in-out 300ms);
				overflow: hidden;
				display: block;
				margin: -1.25em -1.25em 1em;
				padding: 0.5em 1.25em;
				background-color: $color-secondary;
				color: #fff;
				text-decoration: none;

				&:hover {
					@include transition(background ease-in-out 300ms);
					background-color: lighten($color-secondary, 7.5%);

					.roll_time {
						@include transition(background ease-in-out 300ms);
						background-color: transparent;
					}

				}

			}

		}

			.roll_time {
				@include transition(background ease-in-out 300ms);
				margin: -0.6em -1.6em 0.5em;
				padding: 0.5em 1.5em;
				font-size: 0.8em;
				line-height: 1.2em;
				background-color: rgba(0,0,0,0.3);

				strong {
					display: block;
					text-transform: uppercase;
				}

				@include media-breakpoint-up(sm) {
					strong {
						display: inline-block;
						&:after { content: ":"; }
					}
				}

			}

			.roll_title {
				margin: 0;
				font-size: 1.3rem;
			}

		.roll_summary {}