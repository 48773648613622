.info_company {

	line-height: 1em;
	text-align: center;

	.company_name { display: block; font-size: 1.2em }

	.phone {
		font-size: 1.7em;
		line-height: 1.5em;
		font-weight: 800;
		color: $white;
	}

	.company_hours { display: block; margin: 0 0 0.5em }

	.company_address { margin: 0 0 0.5em }

		.company_street { display: block }

	.company_license small { display: block }


	@include media-breakpoint-up(sm) { text-align: left }

}