img {
	display: block;
	width: 100%;
	border-style: none;
}

	.img--caption {
		span {
			display: block;
			margin: 5px 0 0;
			line-height: 1.1em;
		}
	}

	.img--main { margin: 0 0 15px; max-width: 360px; }

	.img--thumbnail {
		padding: 5px;
		border: 1px solid #ddd;
		background-color: #fff;
	}

	.img--right {
		@include media-breakpoint-up(sm) {
			clear: both;
			float: right;
			width: 45%;
			margin: 0 0 15px 15px;
		}
	}

	.img--left {
		@include media-breakpoint-up(sm) {
			clear: both;
			float: left;
			width: 45%;
			margin: 0 15px 15px 0;
		}		
	}

	.img--wide {
		margin: 0 0 15px;
		max-width: none;
	}

	.img--coupon { max-width: 640px; }


.embed-responsive {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;

	&.embed-responsive-16by9 { padding-bottom: 56.25%; }
	&.embed-responsive-4by3 { padding-bottom: 75%; }

		.embed-responsive-item, embed, iframe, object, video {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			height: 100%;
			width: 100%;
			border: 0;
		}

}